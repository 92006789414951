// The debounce function receives our function as a parameter
import {triggerHomepageAnimations} from "./js/animations";

const debounce = (fn) => {
  // This holds the requestAnimationFrame reference, so we can cancel it if we wish
  let frame;

  // The debounce function returns a new function that can receive a variable number of arguments
  return (...params) => {
    // If the frame variable has been defined, clear it now, and queue for next frame
    if (frame) {
      cancelAnimationFrame(frame);
    }

    // Queue our function call for the next frame
    frame = requestAnimationFrame(() => {
      // Call our function and pass any params we received
      fn(...params);
    });
  };
};

// Reads out the scroll position and stores it in the data attribute
// so we can use it in our stylesheets
const storeScroll = () => {
  document.documentElement.dataset.scroll = window.scrollY;
};

// Listen for new scroll events, here we debounce our `storeScroll` function
document.addEventListener("scroll", debounce(storeScroll), { passive: true });

// Update scroll position for first time
storeScroll();

//////// Prevent transition flashing at load

window.addEventListener('load', () => {
  let header = document.querySelector('header');
  header.classList.remove('preload');
});


// Navbar

window.addEventListener('load', () => {
  let mobileOpenElm = document.querySelector('.navbar--mobilecontrols');
  let navbarElm = document.querySelector('.navbar');
  const topLine = document.querySelector('#navbar--hamburger-top-line');
  const bottomLine = document.querySelector('#navbar--hamburger-bottom-line');
  triggerHomepageAnimations();
  mobileOpenElm.addEventListener('click', () => {
    if(!navbarElm.classList.contains('navbar--open')) {
      navbarElm.classList.add('navbar--open');
      navbarElm.classList.add('opening');
      setTimeout(() => {
        navbarElm.classList.remove('opening');
        topLine.classList.add('open');
        bottomLine.classList.add('open');
      }, 100);
    } else {
      navbarElm.classList.remove('navbar--open');
      navbarElm.classList.add('closing');
      setTimeout(() => {
        navbarElm.classList.remove('closing');
        topLine.classList.remove('open');
        bottomLine.classList.remove('open');
      }, 100);
    }
  });
});